import { getPriceStr } from "../lib/utils"
import { getOrderItemInfoUnionpos, getOrderItemInfoOasis } from "./transformOrders"
const pos = {
  "UNIONPOS": { getOrderSummay: getOrderSummaryUnionpos },
  "OASIS_POS": { getOrderSummay: getOrderSummaryOasispos },
}
const posList = Object.keys(pos)

export function getOrderSummay({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "NOT SUPPORTED" } }
  return pos[shopInfo.posName].getOrderSummay({ shopInfo, ...rest })
}

function getOrderSummaryUnionpos({ shopInfo, orderData, itemList, itemOptions }) {
  let orderAmt = 0
  let orderNames = []

  const OrderData = orderData.OrderData

  OrderData.map(order => {
    const idx = itemList.findIndex(item => item.itemCode === order.ItemCode)
    if (idx >= 0) {
      // const { itemAmt, itemTxt, optTxt } = _getItemInfo({ shopInfo, order, item: itemList[idx], itemList, itemOptions })
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoUnionpos({ shopInfo, order, item: itemList[idx], itemList, itemOptions })

      let orderName = `${order.Qty} x ${itemTxt}`
      if (optTxt) orderName += `(${optTxt})`
      orderNames.push(orderName)
      orderAmt += itemAmt
    } else {
      orderNames.push(`없는 메뉴(${order.ItemCode})`)
    }
    return null
  })
  return { orderName: orderNames.join(", "), orderAmt: getPriceStr(orderAmt) }
}

function getOrderSummaryOasispos({ shopInfo, orderData, itemList, itemOptions }) {
  let orderAmt = 0
  let orderNames = []

  orderData.orderList.map(order => {
    console.log(order)
    const idx = itemList.findIndex(item => item.itemCode === order.mCode)
    if (idx >= 0) {
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoOasis({ shopInfo, order, item: itemList[idx], itemList, itemOptions })
      let orderName = `${order.qty} x ${itemTxt}`
      if (optTxt) orderName += `(${optTxt})`
      orderNames.push(orderName)
      orderAmt += itemAmt
    } else {
      orderNames.push(`없는 메뉴(${order.ItemCode})`)
    }
    return null

    // let itemName = order.mName

    // let optNameList = []
    // let optPrice = 0
    // order.optionList.map((opt) => {
    //   optNameList.push(opt.mName)
    //   optPrice += opt.unitprice
    // })

    // const optNames = '(' + optNameList.join(',') + ')'
    // if (optNameList.length > 0) itemName += optNames
    // if (order.qty > 1) itemName += " x " + order.qty

    // orderNames.push(itemName)
    // orderAmt += (parseInt(order.unitPrice) + optPrice) * order.qty
  })

  return { orderName: orderNames.join(' , '), orderAmt: getPriceStr(orderAmt) }
}
