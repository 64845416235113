import {
  shopItemCreate,
  shopItemUpdate,
  shopItemDelete,
  shopItemOptionGet,
  shopItemOptionCreate,
  shopItemOptionUpdate,
} from "../../lib/ec2-api-lib";
import { oasisposProxy } from "./oasis-api-lib";
import { getReqTime_oasispos } from "./oasispos-utils";
import { json2obj } from "../../lib/utils";
// import { items, groups, optionGroups, optionClasses, optionItems, tables } from "./dpos-menu-db";
import { shopItem_oasispos } from "./oasispos-menu-db";
import { debug } from "../../settings";

const Texts = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

// 
// menus는 DUMMY_POS용임
// 
export async function oasisposUpdateMenu({ shopInfo, itemList, itemOptions, setComment }) {
  try {
    const optionName = "OASIS_POS";

    let preOptions = {};
    let isNew = true;

    let res = await shopItemOptionGet({
      shopId: shopInfo.shopId,
      optionName,
    });

    // 
    // 신규이면 preOptions.isNew == true
    // 
    if (res.err) {
      if (res.err.message !== Texts.noItemFound) throw new Error(Texts.alertFail + " at itemOptionGet OASIS_POS");
    } else {
      preOptions = res.result?.Item;
      isNew = false;
    }

    let resp = await updateGroupList({ shopInfo, preOptions, isNew, optionName, setComment });
    if (resp.err) throw Error(resp.err.message);

    resp = await updateSetMenuList({ shopInfo, preOptions, isNew, optionName, setComment });
    if (resp.err) throw Error(resp.err.message);

    resp = await updateTableList({ shopInfo, preOptions, isNew, optionName, setComment });
    if (resp.err) throw Error(resp.err.message);

    resp = await cleanUpItemList({ shopInfo, itemList, setComment });
    if (resp.err) throw Error(resp.err.message);

    if (debug) console.table(resp.posItems)

    resp = await savePosItems({ shopInfo, posItems: resp.posItems, serverItems: resp.serverItems, setComment });
    if (resp.err) throw new Error(resp.err.message);

    if (debug) console.table(resp.newServerItems)

    if (debug) console.log("-".repeat(20), 'save end');
    return {};

  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

async function updateGroupList(props) {
  const { shopInfo, preOptions, isNew, optionName } = props
  try {

    props.setComment('Updating GroupList ...')

    let groupList_pre = json2obj(preOptions.groupList)
    let groupList_pos = []

    const body = {
      // "userIP": "172.30.1.30",
      "branch": shopInfo.storeCode,
      // "tableGroup": "F001",
      // "tableNo": 0,
      "requestTime": getReqTime_oasispos(),
      // "content": "",
    }

    let res = await oasisposProxy({ apiName: "menuGroup", body })
    if (res.err) throw Error(res.err.message)

    groupList_pos = res.result.menuGroupList

    let newGrpList = groupList_pos.map((grp, i) => {
      let newGrp = {};
      let grp_pre = groupList_pre.filter(pre => pre.gcode === grp.gcode)

      if (grp_pre.length === 1) {
        newGrp = {
          ...grp_pre[0],
          displayName: (grp_pre[0].gname === grp.gname) ? grp_pre[0].displayName : grp.gname,
          sortOrder: grp.dporder,
        }
      } else {
        newGrp = {
          ...grp, // gcode, gname, dporder, edate, gcode_parent
          groupCode: grp.gcode,
          groupName: grp.gname,
          displayName: grp.gname,
          sortOrder: grp.dporder,
          nameLang: {},
          hidden: false
        }
      }

      return newGrp
    });

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: {
        groupList: JSON.stringify(newGrpList),
      }
    };

    if (isNew) res = await shopItemOptionCreate(data);
    else res = await shopItemOptionUpdate(data);

    if (res.err) throw new Error(Texts.alertFailUpdate + " - groupList Update");

    return { groupList: newGrpList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

async function updateSetMenuList(props) {
  const { shopInfo, optionName, setComment } = props
  try {

    setComment(`Updating SetMenuList ...`)

    const body = {
      "branch": shopInfo.storeCode,
      "requestTime": getReqTime_oasispos(),
    }

    let setMenuList_pos = []
    let res = await oasisposProxy({ apiName: "setMenu", body })
    if (res.err) {

      // 
      // 세트메뉴 없는 에러가 아닐때만 에러처리
      // 
      if (!res.err.message.includes("세트메뉴가 없습니다")) throw Error(res.err.message)
    } else {
      setMenuList_pos = res.result.setMenuList
    }

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: {
        setMenuList: JSON.stringify(setMenuList_pos),
      }
    };

    res = await shopItemOptionUpdate(data);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - setMenuList");

    return { setMenuList: setMenuList_pos };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

async function updateTableList({ shopInfo, optionName, setComment }) {
  try {

    setComment(`Updating TableList ...`)

    const body = {
      "branch": shopInfo.storeCode,
      "requestTime": getReqTime_oasispos(),
    }

    let res = await oasisposProxy({ apiName: "allTable", body })
    if (res.err) throw Error(res.err.message)

    let tableList = res.result.tableList

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: { tableList: JSON.stringify(tableList) }
    };

    res = await shopItemOptionUpdate(data);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - tableList");

    return { tableList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

async function cleanUpItemList({ shopInfo, itemList, setComment }) {
  try {

    setComment(`Cleaning ItemList ...`)

    const body = {
      "branch": shopInfo.storeCode,
      "requestTime": getReqTime_oasispos(),
    }

    let res = await oasisposProxy({ apiName: "menu", body })
    if (res.err) throw Error(res.err.message)

    let posItems = res.result.menuList

    let errMsg = ""
    let serverItems = [];

    for (let i = 0; i < itemList.length; i++) {
      const serverItem = itemList[i]
      const index = posItems.findIndex(posItem => posItem.mcode === serverItem.itemCode);

      //
      // 포스에 없는 항목 오늘 메뉴DB에서 삭제
      //
      if (index < 0) {
        let res = await shopItemDelete({ shopId: shopInfo.shopId, id: serverItem.id });
        if (res.err) {
          errMsg = "필요없는 메뉴아이템 삭제 중 에러가 발생했습니다. " + res.err.message;
          break;
        }
      } else {
        serverItems.push(serverItem);
      }
    }

    if (errMsg !== "") throw Error(errMsg)

    return { posItems, serverItems }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

async function savePosItems({ posItems, shopInfo, serverItems, setComment }) {
  try {

    const newServerItems = []
    let errMsg = ""

    for (let idx = 0; idx < posItems.length; idx++) {

      setComment(`Updating... Item ${posItems[idx].mcode}`)

      const posItem = posItems[idx]

      const index = serverItems.findIndex((serverItem) => serverItem.itemCode === posItem.mcode);

      let res = {};
      let itembody = {};

      /** itemCode가 없으면 신규 아이템이므로 create 함 */
      if (index < 0) {
        itembody = createMenuItem({ posItem, idx });
        res = await shopItemCreate({ shopId: shopInfo.shopId, body: itembody });
      }

      /** 동일한 itemCode가 있으면 update 함 */
      else {
        itembody = updateMenuItem({ posItem, idx, serverItem: serverItems[index] });
        res = await shopItemUpdate({ shopId: shopInfo.shopId, id: serverItems[index].id, body: itembody });
      }
      if (res.err) {
        errMsg += res.err.message
        break;
      }
      newServerItems.push(itembody);
    }
    if (errMsg !== "") throw Error(errMsg)

    return { newServerItems };
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function createMenuItem({ posItem, idx }) {

  const inputFields = shopItem_oasispos.inputFields
  const newItem = {}

  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]

    // 
    // pos의 기본항목
    // 
    if (field.posName !== undefined) {
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }
    }

    // 
    // ONL 편집 가능 항목
    // 
    else {
      // itemName2 case
      if (field.defaultValue?.posName !== undefined) {
        newItem[field.name] = posItem[field.defaultValue.posName];
      }

      // image case
      else {
        newItem[field.name] = field.defaultValue;
      }
    }
  }
  return newItem
}

function updateMenuItem({ posItem, idx, serverItem }) {
  const inputFields = shopItem_oasispos.inputFields
  const newItem = {}

  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]

    // 
    // pos의 기본항목
    // 
    if (field.posName !== undefined) {
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }
    }

    // 
    // ONL 편집 가능 항목
    // 
    else {
      // console.log(field.name, serverItem[field.name])
      newItem[field.name] = serverItem[field.name];
    }
  }

  /** ItemName 변경되면 displayName도 변경됨. */
  if (serverItem["itemName"] !== posItem["mname"]) {
    newItem["itemName2"] = posItem["mname"]
  }
  return newItem
}

/**
 * "001,002" => ["001", "002"]
 */
// function getArrayFromCodeList(codeList) {
//   try {
//     if (codeList) {
//       const codes = codeList.split(",")
//       const newCodes = codes.map(code => code.trim())
//       return newCodes
//     } else {
//       return []
//     }
//   } catch (e) {
//     return []
//   }
// }
