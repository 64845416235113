import { unionposProxy2, okposProxy } from "../lib/ec2-api-lib"
import { oasisposProxy } from "./oasis-pos/oasis-api-lib"
import { easyposProxy } from "./easypos/easypos-api-lib"
import { getReqTime_oasispos } from "./oasis-pos/oasispos-utils"
import { getTableInfo } from "./getTableInfo"
import { notSupported } from "./posList"
import { debug } from "../settings"

const pos = {
  "UNIONPOS": { sendOrder: sendOrderUnionpos, },
  "OKPOS": { sendOrder: sendOrderOkpos },
  "OASIS_POS": { sendOrder: sendOrderOasispos },
  "EASY_POS": { sendOrder: sendOrderEasypos },
}
const posList = Object.keys(pos)

export async function sendOrder({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return notSupported()
  return pos[shopInfo.posName].sendOrder({ shopInfo, ...rest })
}

// Unionpos
async function sendOrderUnionpos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    let resOrderObj = createOrderDataUnionpos({
      shopInfo, cartItems, itemOptions2, tableNo, token
    })

    if (resOrderObj.err) throw Error(resOrderObj.err.message)

    // console.log('resOrderObj', resOrderObj)
    // throw Error('test')

    const res = await unionposProxy2({
      apiName: 'order',
      body: resOrderObj.result,
      ver
    });

    if (res.err) throw Error(res.err.message)
    if (res.result?.CODE?.includes("E"))
      throw Error(`${res.result.CODE} - ${res.result.MSG}`)

    return res
  } catch (e) {
    return { err: { message: e.message } }
  }
}

export function createOrderDataUnionpos({
  shopInfo, cartItems, itemOptions2, tableNo, token
}) {
  try {
    if (cartItems.length === 0) throw Error("NO CART ITEMS")

    let OrderData = [];
    cartItems.map(item => {
      const orderItem = {
        "ItemCode": item.itemCode,
        "Qty": item.count.toString(),
        "Price": item.price.toString(),
        "IsPack": "C",
      }

      const setMenus = []
      item.options.map(opt => {
        setMenus.push({
          "SetCode": opt.itemCode,
          "SetQty": (opt.count || 1).toString(),
          "Price": opt.optPrice.toString(),
        })
        return null
      })

      if (setMenus.length > 0) orderItem["SetMenu"] = setMenus

      OrderData.push(orderItem)
      return null
    })

    const { table } = getTableInfo({ shopInfo, tableNo, itemOptions2 })
    const orderObj = {
      shopId: shopInfo.shopId,
      StoreCode: shopInfo.storeCode,
      TableCode: table.TableCode,
      OrderData,
      OrderQty: cartItems.length.toString(),
      token: token,
    }

    if (debug) console.log('orderObj', orderObj)
    return { result: orderObj }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

// OKPOS
async function sendOrderOkpos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    // console.log("cartItems", cartItems)
    const orderInfo = []
    cartItems.map((item) => {

      // 
      // 개별 cartItem과 option을 처리함.
      // 
      const { result } = createOrderListOkpos({ item, itemOptions2 })
      result.map(o => orderInfo.push(o))
      return null
    });

    // console.table(orderInfo)

    // throw Error("테스트 중")

    const { table } = getTableInfo({ shopInfo, tableNo, itemOptions2 })
    const orderData = {
      "TABLE_CD": table.TABLE_CD,
      "KITCHEN_MEMO": "테스트 주문입니다.",
      "ORDER_INFO": orderInfo
    }

    const res = await okposProxy({
      apiName: 'order',
      body: {
        token,
        storeCode: shopInfo.storeCode,
        shopId: shopInfo.shopId,
        orderData,
      },
      ver,
    });
    if (res.err) throw Error(res.err.message)
    return res
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function createOrderListOkpos({ item, itemOptions2 }) {
  const orderObjTemplate = {
    "ORDER_TYPE": "Y",
    "PROD_CD": "",    // item.itemCode,
    "PROD_AMT": "",   // item.price,
    "DCM_AMT": "",    // item.price,
    "DC_AMT": "0",
    "POINT_AMT": "0",
    "TAX_AMT": "0",    // item.price/1.1,
    "NOTAX_AMT": "0",
    "PROD_CNT": "1",
    "PROD_TYPE": "0", // 메뉴 타입 (0: 일반, 1: 선택, 2: 속성)

    "SDS_PTCD": "",
    "SDS_GRPCD": "",
    "SDS_CLSCD": "",
    "SDS_CD": "",
    "SDA_CLSCD": "",
    "SDA_CD": "",
    "SDS_ORG_DTL_NO": "",
  }

  const orderList = []
  const sdsOrgDtlNo = (item["SDA_CLS_CD"] !== "" || item["SDS_GRP_CD"] !== "")
    ? Math.floor(Math.random() * 10000).toString().padStart(4, "0")
    : ""

  // 
  // 메인 메뉴 정보 생성
  //  
  const priceStr = item.price
  const taxStr = Math.floor(item.price.toString() * (0.1 / 1.1)).toString()

  orderList.push({
    ...orderObjTemplate,
    "PROD_CD": item.itemCode,
    "PROD_CNT": item.count.toString(),
    "PROD_AMT": priceStr,
    "DCM_AMT": priceStr,
    "TAX_AMT": taxStr,
    "SDS_ORG_DTL_NO": sdsOrgDtlNo
  })

  if (item["SIDE_MENU_YN"] === "Y") {
    // const sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === item.SDS_GRP_CD)

    item.options.map(optItem => {

      // 
      // sds code가 있으면 선택, 아니면 속성
      //
      if (optItem["SDS_CD"] !== undefined && optItem["SDS_CD"] !== "") {
        const p = optItem.optPrice.toString()
        orderList.push({
          ...orderObjTemplate,
          "PROD_CD": optItem.PROD_CD,        // or optionItemCode
          "PROD_CNT": (optItem.count * item.count).toString(),
          "PROD_AMT": p,
          "DCM_AMT": p,
          "TAX_AMT": p,
          "PROD_TYPE": "1",

          "SDS_PTCD": item.itemCode,
          "SDS_GRPCD": item["SDS_GRP_CD"],
          "SDS_CLSCD": optItem.optionClassCode,
          "SDS_CD": optItem.optionItemCode,
          "SDS_ORG_DTL_NO": sdsOrgDtlNo
        })
      }

      // 
      // 속성
      // 
      if (optItem["SDA_CD"] !== undefined && optItem["SDA_CD"] !== "") {
        orderList.push({
          ...orderObjTemplate,
          "PROD_TYPE": "2",
          "SDA_CLSCD": optItem["SDA_CLS_CD"],
          "SDA_CD": optItem["SDA_CD"],
          "SDS_ORG_DTL_NO": sdsOrgDtlNo
        })
      }

      return null

    })
  }

  // 
  // ONL 옵션 메뉴 --> 메뉴 주문 기능부터 카트에는 일반 메뉴로 들어옴
  // 
  // if (item["optionGroupCode"]) {
  //   let classList = getOnlOptionItems({ item, itemOptions2 })
  //   classList.map(cls => {
  //     if (cls.itemList2.length > 0) {
  //       orderInfo.push({
  //         ...orderObjTemplate,
  //         "PROD_CD": cls.itemList2[0].itemCode,
  //         "PROD_AMT": cls.itemList2[0].price.toString(),
  //         "DCM_AMT": cls.itemList2[0].price.toString(),
  //         "TAX_AMT": cls.itemList2[0].price.toString(),
  //         "SDS_ORG_DTL_NO": sdsOrgDtlNo
  //       })
  //     }
  //   })
  // }

  // console.table(orderList)

  return { result: orderList }
}

// OasisPos
async function sendOrderOasispos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    let resOrderObj = createOrderDataOasispos({
      shopInfo, cartItems, itemOptions2, tableNo, token
    })
    if (resOrderObj.err) throw Error(resOrderObj.err.message)

    const res = await oasisposProxy({
      apiName: 'orderInput',
      body: resOrderObj.result,
      ver
    });

    if (res.err) throw Error(res.err.message)
    if (res.result?.errorCode !== "0000") {
      throw Error(`${res.result.errorCode} - ${res.result.errorMsg}`)
    }

    return res
  } catch (e) {
    console.log(e)
    return { err: { message: e.message } }
  }
}

export function createOrderDataOasispos({
  shopInfo, cartItems, itemOptions2, tableNo, token
}) {
  try {
    if (cartItems.length === 0) throw Error("NO CART ITEMS")

    const { table } = getTableInfo({ shopInfo, tableNo, itemOptions2 })

    const orderList = cartItems.map(citem => {

      const { price, qty, amount, vat } = _getPriceQty(citem)

      const orderItem = {
        orderNo: "",                          // String 12, 주문번호 신규주문 '' , 추가주문 - 테이블 상태조회 orderNo
        mGroup: citem.groupCode,              // String 6, 메뉴그룹코드
        mGroupName: citem.groupName,          // String 100, 메뉴그룹명
        // mGroupName: "메인메뉴",              // String 100, 메뉴그룹명
        mCode: citem.itemCode,                // String 6, 메뉴코드
        mName: citem.itemName,                // String 100, 메뉴명
        unitPrice: price,                     // Int, 단가
        qty,                                  // Int, 수량
        amount,                               // Int, unitPrice x qty
        dcAmount: 0,                          // Int, 0
        netAmount: amount - vat,              // Int, 부가세 제외 금액
        vatAmount: vat,                       // Int, 부가세
        isTakeOut: 0,                         // Int, 0 : 매장  1: 포장
        state: "",                            // 정상, 반품, 서비스 - 해당사항 없음
        optionList: []
      }

      orderItem.optionList = citem.options.map(optItem => {

        const price = parseInt(optItem.optPrice)
        const qty = parseInt(optItem.count)
        const amount = price * qty
        const vat = Math.floor(amount * (1 / 11))

        return {
          "mcode": optItem.itemCode,
          "mName": optItem.itemName,
          "unitPrice": price,
          "qty": qty,
          "amount": amount,
          "dcAmount": 0,
          "netAmount": amount - vat,
          "vatAmount": vat
        }
      })

      return orderItem
    })

    const body = {
      "userIP": "172.30.1.30",
      shopId: shopInfo.shopId,
      branch: shopInfo.storeCode,
      tableGroup: table.tableGroup,
      tableNo: table.tableNo,
      requestTime: getReqTime_oasispos(),
      content: "",
      personCnt: 1,
      orderList
    }

    return { result: body }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function _getPriceQty(item) {
  const price = parseInt(item.price)
  const qty = parseInt(item.count)
  const amount = price * qty
  const vat = Math.floor(amount * (1 / 11))
  return { price, qty, amount, vat }
}


async function sendOrderEasypos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    console.log('-'.repeat(50), "OrderData")
    console.log('cartItems', cartItems)

    let resOrderObj = createOrderDataEasypos({
      shopInfo, cartItems, itemOptions2, tableNo, token, ver
    })
    if (resOrderObj.err) throw Error(resOrderObj.err.message)

    console.log(JSON.stringify(resOrderObj.result, null, 2))

    const orderBody = resOrderObj.result

    const tableOrderInfoBody = {
      tableGroupCode: orderBody.tableGroupCode,
      tableCode: orderBody.tableCode
    }

    let res = await easyposProxy({ apiName: "tableOrderInfo", body: tableOrderInfoBody })

    if (res.err) {

      if (res.err.message?.includes("RETURN_NO_DATA")) {
        console.log('-'.repeat(50), '신규 주문 처리')
        return easyposProxy({ apiName: "order", body: orderBody })
      }

      else throw Error(res.err.message)
    }

    else {
      console.log('-'.repeat(50), '추가 주문 처리')
      console.log('order Items', res.result.body.itemList)

      const orderAddBody = { ...orderBody }
      const newItemList = orderAddBody.itemList.map(oItem => {
        return {
          ...oItem,
          "updateType": "A",                    // A : 추가 주문, M : 주문 수정, D : 주문 취소
          "orderIndex": "01",
          "sDetailNo": "0001",                  // updateType === A 인 경우
        }
      })
      orderAddBody.itemList = newItemList
      return easyposProxy({ apiName: "orderAdd", body: orderAddBody })
    }

  } catch (e) {
    // console.log(e)
    return { err: { message: e.message } }
  }
}

export function createOrderDataEasypos({
  shopInfo, cartItems, itemOptions2, tableNo, token, ver
}) {
  try {
    if (cartItems.length === 0) throw Error("NO CART ITEMS")

    const { table } = getTableInfo({ shopInfo, tableNo, itemOptions2 })


    // console.log('table', table)

    const orderList = cartItems.map(citem => {

      // console.log("cartItem", citem)

      const orderItem = {
        itemCode: citem.itemCode,
        qty: citem.count,
        itemPrice: parseInt(citem.price),
        dcAmt: 0,
        // subItemList: []
      }

      // orderItem.optionList = citem.options.map(optItem => {

      //   const price = parseInt(optItem.optPrice)
      //   const qty = parseInt(optItem.count)
      //   const amount = price * qty
      //   const vat = Math.floor(amount * (1 / 11))

      //   return {
      //     "mcode": optItem.itemCode,
      //     "mName": optItem.itemName,
      //     "unitPrice": price,
      //     "qty": qty,
      //     "amount": amount,
      //     "dcAmount": 0,
      //     "netAmount": amount - vat,
      //     "vatAmount": vat
      //   }
      // })

      return orderItem
    })

    const body = {
      shopId: shopInfo.shopId,
      // shopNo: shopInfo.storeCode,
      "tableGroupCode": table["TABLE_GROUP_CODE"],
      "tableCode": table.tableCode,
      "paymentFlag": "N",                     // Y 선결제, N 후결제, P 연동 선결제
      "orderType": "N",                       // N 일반주문, D 배달, T 포장
      itemList: orderList,
      token,
      ver
    }

    return { result: body }
  } catch (e) {
    return { err: { message: e.message } }
  }
}
