import {
  shopItemCreate,
  shopItemUpdate,
  shopItemDelete,
  shopItemOptionGet,
  shopItemOptionCreate,
  shopItemOptionUpdate,
  unionposProxy2, unionposCopyImage
} from "../../lib/ec2-api-lib";
import { shopItem } from "./menu-db";
import { isJson, json2obj } from "../../lib/utils";
import { debug } from "../../settings";

const Texts = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

const optionNames = {
  unionPos: "unionpos",
  dummyPos: "DUMMY_POS"
}

// const menuSave = true;

// 
// POS 언어지원은 사용하지 않음. 삭제할 것
// 
const getLangList = async ({
  shopInfo,
  optionName,
  body
}) => {
  try {

    let groupLangList = [];
    let itemLangList = [];
    let res;

    if (optionName === optionNames.unionPos) {
      res = await unionposProxy2({ apiName: 'langList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos langList");

      groupLangList = res.result.DATA.GrpLangList;
      itemLangList = res.result.DATA.ItemLangList;
    }
    // else {
    //   throw new Error(Texts.alertFail + " - DummyPos LangList Not Supported");
    // }

    return { groupLangList, itemLangList }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const setItemGroups = async ({
  shopInfo,
  body,
  preOptions,
  isNew,
  optionName,
  groupLangList,
  menus,
  setComment
}) => {
  try {

    let GrpList_pre = [];
    let MidGrpList_pre = []; // 현재는 필요없음.

    let GrpList = [];
    let MidGrpList = []

    if (isJson(preOptions.GrpList)) GrpList_pre = JSON.parse(preOptions.GrpList);
    if (isJson(preOptions.MidGrpList)) MidGrpList_pre = JSON.parse(preOptions.MidGrpList);

    // if (debug) console.log('GrpList_pre', GrpList_pre);

    let res;

    if (optionName === optionNames.unionPos) {
      setComment("Receiving GroupList...")
      res = await unionposProxy2({ apiName: 'groupList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos GrpList");

      GrpList = res.result.DATA?.GrpList || [];
      MidGrpList = res.result.DATA?.MidGrpList || [];
    } else {
      GrpList = menus.GrpList;
    }

    if (debug) console.log('pre GrpList', GrpList);

    // 
    // GrpList 를 기준으로 매핑하면 자연스럽게 GrpList_pre에서 삭제하는 효과가 있다.
    // 
    let newGrpList = GrpList.map((grp, i) => {

      let newGrp = {};

      // 
      // pre group이 있으면 merge
      // 
      let grp_pre = GrpList_pre.filter(grpPre => grpPre.GrpCode === grp.GrpCode);
      if (grp_pre.length === 1) {
        newGrp = {
          ...grp_pre[0],
          GrpName: grp.GrpName, name: grp.GrpName,      // 항상 GrpName 이어야 함.
          nameLang: grp_pre[0].nameLang || {},
          displayName: grp_pre[0].GrpName === grp.GrpName ? grp_pre[0].displayName : grp.GrpName,
          sortOrder: grp_pre[0].sortOrder || 0,
          hidden: grp_pre[0].hidden || false
        };

        if (debug) console.table([grp_pre[0], grp, newGrp])
      }

      // 
      // pre group이 없으면 생성
      //
      else {
        newGrp = {
          ...grp,
          nameLang: {},
          name: grp.GrpName,
          displayName: grp.GrpName,
          sortOrder: grp.GrpLineup
        }
      }

      // 
      // POS 언어지원: posNameLang 으로 별도 저장
      // 
      let langs = groupLangList.filter(langGrp => langGrp.GrpCode === grp.GrpCode)
      let posNameLang = { KR: "", EN: "", JP: "", CN: "" }

      if (langs.length === 1) {
        posNameLang = {
          KR: langs[0].GrpName_KR || "",
          EN: langs[0].GrpName_EN || "",
          JP: langs[0].GrpName_JP || "",
          CN: langs[0].GrpName_CN || "",
        }
      }
      newGrp.posNameLang = posNameLang; // GrpList 전체가 JSON으로 저장되므로 JSON.stringify가 필요없음.

      return newGrp;
    });

    // if (debug) console.log('newGrpList json len:', JSON.stringify(newGrpList).length)

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: {
        GrpList: JSON.stringify(newGrpList),
        MidGrpList: JSON.stringify(MidGrpList),
      }
    };

    if (isNew) {
      res = await shopItemOptionCreate(data);
      if (debug) console.log('res create GrpList, MidGrpList', res);
    } else {
      res = await shopItemOptionUpdate(data);
      if (debug) console.log('res update GrpList, MidGrpList', res);
    }

    if (res.err) throw new Error(Texts.alertFailUpdate + " - GrpList, MidGrpList Update");

    return { GrpList: newGrpList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const setTableList = async ({
  shopInfo,
  body,
  preOptions,
  isNew,
  optionName,
  menus,
  setComment
}) => {
  try {
    let TableList = [];

    let res;
    if (optionName === optionNames.unionPos) {
      setComment("Receiving TableList...")
      res = await unionposProxy2({ apiName: 'tableList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos TableList");
      TableList = res.result.DATA.TableList;
    } else {
      TableList = menus.TableList;
    }

    const preTableList = json2obj(preOptions.TableList)

    let newTableList = []

    TableList.map(table => {
      const idx = preTableList.findIndex(pre => pre.TableCode === table.TableCode)

      let PosNo = ""
      if (idx >=  0) PosNo = preTableList[idx].PosNo || ""
      newTableList.push({
        FloorNum: table.FloorNum,
        TableCode: table.TableCode,
        TableName: table.TableName,
        TableType: table.TableType,
        TableNo: table.TableNo,
        PosNo
      })
      return null
    });

    const data = {
      shopId: shopInfo.shopId,
      optionName: optionName,
      body: { TableList: JSON.stringify(newTableList) }
    };

    res = await shopItemOptionUpdate(data);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - itemOptions Update");
    if (debug) console.log('res update TableList', res);

    return { TableList: newTableList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const createMenuItems = async ({
  shopInfo,
  GrpList,
  body,
  optionName,
  itemLangList,
  menus,
  setComment
}) => {
  try {
    let res;
    let ChoiceMenuList = [];
    let SetMenuList = [];
    let ItemList = [];

    if (optionName === optionNames.unionPos) {
      setComment('Receiving ItemList...')
      res = await unionposProxy2({ apiName: 'itemList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos itemList");

      ChoiceMenuList = res.result.DATA.ChoiceMenuList;
      SetMenuList = res.result.DATA.SetMenuList;
      ItemList = res.result.DATA.ItemList;
    } else {
      ChoiceMenuList = menus.ChoiceMenuList;
      SetMenuList = menus.SetMenuList;
      ItemList = menus.ItemList;
    }

    if (debug) console.log('pos ItemList', ItemList)

    res = await shopItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName,
      body: {
        ChoiceMenuList: JSON.stringify(ChoiceMenuList),
        SetMenuList: JSON.stringify(SetMenuList),
      }
    });
    if (res.err) throw new Error(Texts.alertFailUpdate + " - itemOptions Update");
    if (debug) console.log('res update SetMenuList, ChoiceMenuList', res);

    let newItemList = [];

    // GrpList.map((item) => {
    //   const res_ar = ItemList.filter((e) => e.GrpCode === item.GrpCode);
    //   res_ar.forEach((element) => {
    //     newItemList.push({ ...element, GrpName: item.GrpName });
    //   })
    //   return null;
    // });

    // 
    // GrpName 추가
    // 
    ItemList.map((item, i) => {

      const groups = GrpList.filter((group) => group.GrpCode === item.GrpCode);
      if (groups.length === 1) {
        newItemList.push({ ...item, GrpName: groups[0].GrpName }); // posItems 이므로 GrpName을 적용함.
      }
      return null;
    });

    // 
    // POS item nameLangs 추가
    //     
    const newList = newItemList.map(newItem => {
      let posNameLang = { KR: "", EN: "", JP: "", CN: "" }
      const langs = itemLangList.filter((e) => e.ItemCode === newItem.ItemCode);
      if (langs.length === 1) {
        posNameLang = {
          KR: langs[0].ItemName_KR || "",
          EN: langs[0].ItemName_EN || "",
          JP: langs[0].ItemName_JP || "",
          CN: langs[0].ItemName_CN || "",
        }
      }
      return { ...newItem, posNameLang: JSON.stringify(posNameLang) }
    })

    return { posItems: newList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

// 
// DUMMY_POS 이면 image 체크하지 않음.
// 
const setImages = async ({ optionName, posItems, body, setComment }) => {
  try {
    if (optionName === optionNames.unionPos) {
      setComment('Receiving ImageList...')
      let res = await unionposProxy2({ apiName: 'imageList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos imageList");

      let arr = res.result.DATA.ItemImageList;

      arr.map((item, idx) => {
        const index = posItems.findIndex((e) => e.ItemCode === item.ItemCode);
        if (index >= 0) {
          posItems[index] = {
            ...posItems[index],
            ImagePath: item.ImagePath
          }
        }
        return null;
      });
    }

    return { posItems }

  } catch (e) {
    return { err: { message: e.message } };
  }
}

const setDescriptions = async ({ optionName, posItems, body, menus, setComment }) => {
  try {
    let descList = [];

    if (optionName === optionNames.unionPos) {
      setComment('Receiving DescriptionList...')
      let res = await unionposProxy2({ apiName: 'descriptionList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos descriptionList");
      descList = res.result.DATA.ItemDescriptionList;
    } else {
      descList = menus.ItemDescriptionList || []
    }

    if (debug) console.log("descList", descList)

    descList.map((desc,) => {
      const index = posItems.findIndex((e, idx) => e.ItemCode === desc.ItemCode);
      if (index > -1) {
        let posDescriptionLang = { KR: "", EN: "", JP: "", CN: "" }
        if (desc["ItemDescription_EN"]) posDescriptionLang["EN"] = desc["ItemDescription_EN"]
        if (desc["ItemDescription_CN"]) posDescriptionLang["CN"] = desc["ItemDescription_CN"]
        if (desc["ItemDescription_JP"]) posDescriptionLang["JP"] = desc["ItemDescription_JP"]
        if (desc["ItemDescription"]) posDescriptionLang["KR"] = desc["ItemDescription"] // KR이 별도로 없음

        posItems[index] = {
          ...posItems[index],
          ItemDescription: desc.ItemDescription,
          GrpCode: posItems[index].GrpCode, // description.GrpCode가 item.GrpCode와 다른 경우가 있어서 추가함
          posDescriptionLang: JSON.stringify(posDescriptionLang)
        }
      }
      return null;
    });

    return { posItems }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

// 
// DUMMY_POS 이면 체크하지 않음.
// 
const setSoldouts = async ({ optionName, posItems, body, setComment }) => {
  try {
    if (optionName === optionNames.unionPos) {
      setComment('Receiving Soldouts...')
      let res = await unionposProxy2({ apiName: 'soldoutList', body });
      if (res.err) throw new Error(Texts.alertFail + " - unionpos soldoutList");

      let arr = res.result.DATA.ItemSoldoutList;

      arr.map((item) => {
        const index = posItems.findIndex((posItem) => posItem.ItemCode === item.ItemCode);
        if (index > -1) {
          posItems[index] = {
            ...posItems[index],
            ...item,
            GrpCode: posItems[index].GrpCode
          }
        }
        return null;
      });
    }
    return { posItems };
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const cleanUpItemList = async ({ posItems, itemList, shopInfo, setComment }) => {
  try {
    let newItemList = [];
    setComment('Clean up ItemList...')
    await Promise.all(itemList.map(async (serverItem, i) => {
      const index = posItems.findIndex(posItem => posItem.ItemCode === serverItem.itemCode);

      // 
      // 포스에 없는 항목 오늘 메뉴DB에서 삭제
      // 
      if (index < 0) {
        let res = await shopItemDelete({
          shopId: shopInfo.shopId,
          id: serverItem.id
        });
        if (res.err) {
          alert("필요없는 메뉴아이템 삭제 중 에러가 발생했습니다. 한번 더 수행해 주세요!");
        }

        // console.log('deleted item:', serverItem.itemCode, serverItem.itemName);
      } else {
        newItemList.push(serverItem);
      }

      return null;
    }));

    return { posItems, newItemList }
  } catch (e) {
    return { err: { message: e.message } };
  }
}

const savePosItems = async ({ posItems, shopInfo, newItemList, inputFields, preOptions, setComment }) => {
  try {
    let cnt = 0
    let menuSettings = {};
    if (isJson(preOptions.menuSettings)) menuSettings = JSON.parse(preOptions.menuSettings);
    // if (debug) console.log('menuSettings', menuSettings)
    if (debug) console.log('posItems len    =', posItems.length)
    if (debug) console.log('newItemList len =', newItemList.length)

    const serverItems = newItemList;

    // setComment('Saving Pos Items...')

    const newServerItems = await Promise.all(posItems.map(async (posItem, idx) => {

      const index = serverItems.findIndex((serverItem) => serverItem.itemCode === posItem.ItemCode);
      let itembody = {};
      let res;

      /** itemCode가 없으면 신규 아이템이므로 create 함 */
      if (index < 0) {
        itembody = createItemByInputFields({ inputFields, posItem, serverItem: {}, idx });

        // Copy Image
        itembody = await copyImageToS3IfImageExist({ shopId: shopInfo.shopId, itembody, menuSettings });

        res = await shopItemCreate({ shopId: shopInfo.shopId, body: itembody });
        // if (debug) console.log('cnt create', cnt++)
        setComment(`Create Item - ${itembody.itemCode}`)
        if (res.err) throw new Error(res.err.message);
        return itembody;
      }

      /** 동일한 itemCode가 있으면 update 함 */
      else {
        itembody = createItemByInputFields({ inputFields, posItem, serverItem: serverItems[index], idx });

        // Copy Image
        itembody = await copyImageToS3IfImageExist({ shopId: shopInfo.shopId, itembody, menuSettings });

        res = await shopItemUpdate({ shopId: shopInfo.shopId, id: serverItems[index].id, body: itembody });
        // if (debug) console.log('cnt update', cnt++)
        setComment(`Update Item - ${itembody.itemCode}`)
        if (res.err) throw new Error(res.err.message);
        return itembody;
      }
      // return null;
    }));

    return { newServerItems };
  } catch (e) {
    return { err: { message: e.message } }
  }
}

async function copyImageToS3IfImageExist({ shopId, itembody, menuSettings }) {

  if (debug) {
    // if(itembody.oriImage) console.log(itembody.oriImage)
    // if(itembody.image) console.log(itembody.image)
  }

  /**
   * @info_server_image가_없으면_수행함
   *  case 1: create pos oriImage O, server image X   -> 수행
   *  case 2: update pos oriImage O, server image X   -> 수행
   *  case 3: update pos oriImage O, server image O
   *    case 3.1 menuSettings.posImageFirst = true                 -> 수행
   *    case 3.2 menuSettings.posImageFirst = false or undefined   -> 수행하지 않음
   * 
   * @case3_필요하면_옵션을_추가하여_해결할_것
   */

  // 
  // case 제외: 포스 이미지가 없으면 리턴
  // 
  if (itembody.oriImage === undefined || itembody.oriImage === "") {
    // if (debug) console.log(itembody.itemCode, "no Image")
    return itembody;
  }

  // 
  // case3.2 서버 이미지가 있으나, 포스 이미지 우선이 아니면 리턴, 즉 서버 이미지 우선이 기본이다.
  // 
  // if (itembody.image !== undefined && itembody.image !== "") {
  if (itembody.image) {
    if (!menuSettings.posImageFirst) {
      // if (debug) console.log(itembody.itemCode, "image exist and posImageFirst undefined or false");
      return itembody;
    }
  }

  // 
  // case 1 | case 2 | case 3.1
  // 
  if (debug) console.log(itembody.itemCode, "oriImage", itembody.oriImage)

  const res = await unionposCopyImage({ body: { shopId, imageUrl: itembody.oriImage } });
  if (res.status) itembody.image = res.result?.image;
  if (debug) console.log('res copyImage', res.result?.image);

  return itembody;
}

/**
 * @info_메뉴항목_생성_변경에서_가장_중요한_함수임 
 * 
 *  1. field.posName 이 있는 경우 
 *    1.1 posItem이 있는 경우 (오늘 서버에 항목이 있던 없던 무조건 항목 저장)
 *    1.2 posItem이 없는 경우 (undefined) -> 스킵
 *  2. field.name 만 있는 경우
 *    2.1. 오늘서버에 항목이 있는 경우
 *    2.2. 오늘서버에 항목이 없는 경우
 *      2.2.1 field.defaultValue.posName 있는 경우
 *      2.2.2 field.defaultValue 가 value 인 경우
 *  3. field.name 도 없는 경우: namaLang, descriptionLang, serviceTime
 *    defaultValue가 undefined 임
 * 
 * @TODO serverItem 있고 없고에 따라 로직을 다시 해울 것
 */
function createItemByInputFields({ inputFields, posItem, serverItem, idx }) {
  let newItem = {};

  for (let i = 0; i < inputFields.length; i++) {
    let field = inputFields[i]; // 형태 : { name: "itemName", posName: "ItemName" }

    /** 1. field.posName 있는 경우 pos 정보 그냥 저장 - 편집 불가*/
    if (field.posName !== undefined) {

      /** 1.1 posItem이 없으면, 즉 undefined 이면 저장하지 않음. */
      if (posItem[field.posName] !== undefined) {
        newItem[field.name] = posItem[field.posName];
      }

      /** 2. field.name 만 있는 경우 오늘 정보 - 편집 가능 */
    } else {

      /** 2.1 오늘 서버에 항목이 있는 경우 - 기존 item 이 있음 */
      if (serverItem[field.name] !== undefined) {
        newItem[field.name] = serverItem[field.name];
      }

      /** 2.2 오늘 서버에 항목이 없는 경우 - 최초 가져오기 */
      else {

        /** 2.2.1 기본값을 posItem에서 가져옴 */
        if (field.defaultValue?.posName !== undefined) {
          // if(posItem.itemName==="콜라") console.log("field.defaultValue?.posName", field.defaultValue?.posName);
          newItem[field.name] = posItem[field.defaultValue.posName];
        }

        /** 2.2.2 기본값을 defaultValue로  */
        else {
          // 
          // v0.3.9 부터 적용 포스 정보를 기본으로 세팅함.
          // 
          if (field.name === "sortOrder") newItem[field.name] = idx;
          if (field.name === "image") newItem[field.name] = "";         // 나중에 image Copy 하므로 여기서는 ""
          if (field.name === "removed") newItem[field.name] = false;
          if (field.name === "isSoldout") newItem[field.name] = posItem["SoldOut"] === "Y";
          if (field.name === "isNew") newItem[field.name] = posItem["ItemTagNew"] === "Y";
          if (field.name === "isHot") newItem[field.name] = posItem["ItemTagHot"] === "Y";
          if (field.name === "isBest") newItem[field.name] = posItem["ItemTagBest"] === "Y";
        }
      }

      // 
      // nameLang | serviceTime ---- innputField가 없더라도 그냥 유지된다. 단 defaultValue가 undefined 이다.
      //
    }
  }

  /** ItemName 변경되면 displayName도 변경됨. */
  if (serverItem["itemName"] !== posItem["ItemName"]) {
    newItem["itemName2"] = posItem["ItemName"]
  }

  return newItem;
}

/** @TODO SetMenuList ChoiceMenuList 불필요항목 삭제 */

// 
// menus는 DUMMY_POS용임
// 
export async function updateUnionposMenu({ shopInfo, itemList, itemOptions, menus, setComment }) {
  try {
    // 
    // shpItemOption 테이블에 저장할 key { shopId, optionName=unionpos }
    // 
    // const optionName = "unionpos";
    const optionName = shopInfo.posName === "UNIONPOS" ? optionNames.unionPos : optionNames.dummyPos;
    let preOptions = {};
    let isNew = true;

    setComment("Receiving ItemOptions...")

    let res = await shopItemOptionGet({
      shopId: shopInfo.shopId,
      optionName,
    });

    // 
    // 신규이면 preOptions.isNew == true
    // 
    if (res.err) {
      if (res.err.message !== Texts.noItemFound)
        throw new Error(Texts.alertFail + " - itemOptionGet unionpos");
    } else {
      preOptions = res.result?.Item;
      isNew = false;
    }

    if (debug) {
      console.log('optionName', optionName)
      console.log('menus', menus)
      console.log("isNew", isNew)
      console.log("preOptions", preOptions)
    }

    let resp;
    const body = { "StoreCode": shopInfo.storeCode };

    // 
    // 언어 조회
    // 
    const { groupLangList, itemLangList, err } = await getLangList({ shopInfo, optionName, body })
    if (err) throw new Error(resp.err.message);
    if (debug) {
      // console.log('groupLangList', groupLangList);
      console.table(groupLangList)
      // console.log('itemLangList', itemLangList);
      console.table(itemLangList)
    }

    // 
    // GrpList | MidGrpList 를 JSON으로 저장함.
    // 
    resp = await setItemGroups({
      shopInfo,
      body,
      preOptions,
      isNew,
      optionName,
      groupLangList,
      menus,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);
    if (debug) console.log('resp newGrpList', resp.GrpList);

    // 
    // TableList : v0.3.8 에서 추가, v0.3.9 에서 이 순서로 변경
    // 
    let respTableList = await setTableList({
      shopInfo,
      body,
      preOptions,
      isNew,
      optionName,
      menus,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);
    if (debug) console.log('resp tableList', respTableList);

    // 
    // ChoiceMenuListList | SetMenuList 를 JSON으로 저장
    // posItems = ItemList
    // 
    resp = await createMenuItems({
      shopInfo,
      GrpList: resp.GrpList,
      body,
      isNew,
      optionName,
      menus,
      itemLangList,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    if (debug) {
      console.log('posItems', resp.posItems.length, resp.posItems);
      // resp.posItems.map(item => {
      //   const arr = itemLangList.filter((lang=>lang.ItemCode===item.ItemCode))
      //   if (arr.length === 1)
      //     console.log(item.ItemCode, item.ItemName, item.nameLang);
      //   return null
      // })
    }
    // throw Error("Test...")

    resp = await setImages({
      shopInfo,
      posItems: resp.posItems,
      body,
      isNew,
      optionName,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    resp = await setDescriptions({
      shopInfo,
      posItems: resp.posItems,
      body,
      isNew,
      optionName,
      menus,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);
    if (debug) console.log('resp desriptions', resp);

    // return {}

    resp = await setSoldouts({
      shopInfo,
      posItems: resp.posItems,
      body, isNew, optionName,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);
    if (debug) console.log('resp soldouts', resp);

    // 
    // pos에 없는 항목은 itemList에서 삭제
    // 
    resp = await cleanUpItemList({
      shopInfo,
      posItems: resp.posItems,
      itemList,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    resp = await savePosItems({
      posItems: resp.posItems,
      shopInfo,
      itemList,
      newItemList: resp.newItemList,
      inputFields: shopItem.inputFields,
      optionName,
      preOptions,
      setComment
    });
    if (resp.err) throw new Error(resp.err.message);

    // if (debug) {
    //   resp.newServerItems.map(newItem => {
    //     if (newItem.image) console.log(newItem.itemCode, 'image=' + newItem.image);
    //     return null;
    //   })
    // }

    if (debug) console.log("-".repeat(20), 'save end');
    return {};
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/** @deprecated */
export async function resetUnionposMenu({ shopInfo, itemList, itemOptions }) {
  try {

    // 
    // reset GrpList
    //  
    const groupList = isJson(itemOptions.GrpList) ? JSON.parse(itemOptions.GrpList) : [];

    const newGrpList = await Promise.all(groupList.map(async group => {
      // console.log(group.GrpCode, group.name, group.displayName, group.hidden)
      return {
        ...group,
        name: group.GrpName,
        displayName: group.GrpName,
        hidden: false
      }
    }))

    if (debug) {
      newGrpList.forEach(group => {
        if (group.GrpName !== group.displayName) {
          console.log("Reset Failed Grp Found")
          console.log('  - ', group.GrpCode, group.name, group.displayName, group.hidden)
        }
      })
    }

    let res = await shopItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName: optionNames.unionPos,
      body: { GrpList: JSON.stringify(newGrpList) }
    });
    if (res.err) throw Error(res.err.message + ` - 그룹리스트 초기화 중`);

    // 
    // reset itemList
    // 
    await Promise.all(itemList.map(async item => {
      const newItem = {
        itemName2: item.itemName,
        nameLang: JSON.stringify([]),
        description: "",
        descriptionLang: JSON.stringify([]),
        image: "",
        removed: false,
        isSoldout: item.itemSoldOut === "Y",
        isNew: item.itemTagNew === "Y",
        isHot: item.itemTagHot === "Y",
        isBest: item.itemTagBest === "Y",
      }

      const res = await shopItemUpdate({ shopId: shopInfo.shopId, id: item.id, body: newItem })
      if (res.err) throw Error(res.err.message + ` - 메뉴항목 itemCode ${item.itemCode} 초기화 중`);

      return newItem;
    }));

    return {}
  } catch (e) {
    return { err: { message: "초기화 과정에서 에러가 발생하였습니다. 관리자에게 문의하세요!\n" + e.message } }
  }
}