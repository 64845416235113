import React, { useEffect, useState } from "react";
import { Page, Button, CardContainer, useStyles } from "../../../components";
import { useSelector } from "react-redux";
import { getOrderStatusList, getLastOrderTime, sendSmsText } from "../../../lib/ec2-api-lib";
import { attachPolicy } from "../../../lib/pay-api-lib";
// import alarm01 from "../../../assets/sound/alarm01.mp3";
import { subscribe } from "../../../lib/pubsubLib";
import { useInterval } from "../../../lib/hooksLib"
import { sortObjArray2, isJson, getSecurePhoneNumber } from "../../../lib/utils";
import { Clock, ConnectSign } from "../../../components/Icons";
import ModalOrderStatus from "./Modal-orderStatus";
import { Auth } from 'aws-amplify';
import { getItemHtmlInfo } from "../../../lib-pos/transformOrders";
import { debug } from "../../../settings";
import { exampleOrderList } from "../OrderHistrory/example-orderList-oasis";
// import { sendKakaoAlim } from "../../../lib/pay-api-lib";

export const orderStatus = {
  ordered: "O",
  accepted: "A",
  serveReady: "R"
}

const alarmSrc = "https://psdl-oneul-storage.s3.ap-northeast-2.amazonaws.com/public/test/alarmSound.mp3"

export default function OrderStatus(props) {
  const { shopInfo } = useSelector(state => state.shop);
  const { itemList3, itemOptions3 } = useSelector(state => state.items)

  const itemList = itemList3
  const { isMobileDevice, isMobilePhone } = useSelector(state => state.platform);
  const { gStyle, colors } = useStyles()

  const [isLoading, setIsLoading] = useState(false);
  const [lastOrderTime, setLastOrderTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(Date.now())
  const [orderList, setOrderList] = useState([]);

  const [visible, setVisible] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)

  const [iotReady, setIotReady] = useState(false)
  const topic = `/${shopInfo.shopId}/notify-order`

  const updateIntvl = 10

  const [alarmSound, setAlarm] = useState(null)
  // const audioRef = useRef(null)

  useEffect(() => {
    // const myAudio = new Audio(alarm01)
    const myAudio = new Audio(alarmSrc)
    setAlarm(myAudio)
  }, [setAlarm])

  useEffect(() => {
    async function attchIotPolicy() {
      const cred = await Auth.currentCredentials();
      const identityId = cred.identityId

      let res = await attachPolicy({ identityId })
      if (res.err) {
        if (debug) console.log('error attachPolicy', res.err.message)
        setIotReady(false)
      }
      else {
        if (debug) console.log('attachPolicy', res.result)
        setIotReady(true)
      }
    }
    attchIotPolicy()
  }, [])

  useEffect(() => {
    if (!iotReady) return
    let sub1 = subscribe({ topic, messageHandler: handleSub });

    return () => {
      sub1.unsubscribe();
      // console.log("Unsubscribe TableResponse");
    }
  }, [iotReady]);

  useEffect(() => { if (iotReady) updateOrderList() }, [iotReady])

  useInterval(() => { setCurrentTime(Date.now()) }, 1000)

  useInterval(() => { if (!iotReady) updateOrderList() }, updateIntvl * 1000)

  const pageProps = {
    ...props, shopInfo,
    itemList, itemOptions: itemOptions3,
    isMobileDevice, currentTime
  }

  const handleSub = (msg) => {
    try {
      const d = msg.value
      // console.log("msg.value", d);
      if (debug) {
        console.log("elapsed", Date.now() - d.created);
      }

      const newOrder = {
        ...d,
        orderData: isJson(d.orderData) ? JSON.parse(d.orderData) : {}
      }

      setOrderList(pre => {
        const newOrderList = [...pre]
        newOrderList.push(newOrder)
        return newOrderList
      })
      audioPlay()
      setLastOrderTime(pre => {
        if (debug) {
          console.log('pre', pre, new Date(pre).toLocaleString())
          console.log('new', new Date(d.created).toLocaleString())
        }
        return d.created
      })
    } catch (e) {
      alert("ERROR at Notified Order", e.message)
    }
  };

  const audioPlay = () => {
    if (alarmSound?.readyState === 4) alarmSound?.play()
  }

  const onClickTestBtn = () => audioPlay()

  const updateOrderList = async (force) => {
    try {

      setIsLoading(true)

      let res = await getLastOrderTime({ shopId: shopInfo.shopId })
      if (res.err) throw Error(res.err.message)

      const newOrderTime = res.result.lastOrderTime
      if (lastOrderTime >= newOrderTime) {
        if (debug) console.log('NO New Orders')
        return null
      }

      setLastOrderTime(pre => {
        if (debug) {
          console.log('pre orderTime', pre, new Date(pre).toLocaleString())
          console.log('new orderTime', new Date(newOrderTime).toLocaleString())
        }
        return newOrderTime
      })

      res = await getOrderStatusList({ shopId: shopInfo.shopId });
      if (res.err) throw Error(res.err.message)

      const newOrderList = res.result.Items.map(item => {
        return {
          ...item,
          orderData: isJson(item.orderData) ? JSON.parse(item.orderData) : {}
        }
      })

      const newOrderList2 = sortObjArray2({ arr: newOrderList, key: "created", type: "up" })
      setOrderList(newOrderList2);

      if (newOrderList2.length > 0) {
        if (debug) console.log('audio....')
        audioPlay()
      }
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickUpdateOrder = async (order) => {
    setSelectedOrder(order)
    setVisible(true)
  }

  const styles = {
    header: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    orderBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: isMobilePhone ? "column" : "row",
      width: "100%",
      borderRadius: 10,
      margin: 5,
      padding: 5,
      cursor: "pointer"
    },
  }

  return (
    <Page>
      <CardContainer
        title="주문 처리 및 통보"
        onClickBack={() => props.history.push("/")}
        variant="lg"
      >
        <Button 
          style={{marginBottom: 5}}
          variant="primary" 
          size="sm" 
          onClick={updateOrderList}
          isLoading={isLoading} >데이터 가져오기</Button>

        <div style={styles.header}>
          <div>
            <h5 style={gStyle.subtitle}>
              <span style={{ fontWeight: "bold", color: colors.danger }}>{orderList.length} 개의 주문</span>이 대기 중입니다.
            </h5>
            {orderList.length > 0 &&
              <div style={{ display: "flex", alignItems: "center" }}>
                <Clock />
                <h5 style={{ ...gStyle.subtitle, marginLeft: 5 }}>
                  마지막 주문 시간: {new Date(lastOrderTime).toLocaleTimeString()}
                </h5>
              </div>
            }
          </div>

          {iotReady && <p style={gStyle.subtitle}>실시간 연결 <ConnectSign /></p>}
          {!iotReady && <p style={gStyle.subtitle}>{`${updateIntvl}초 간격으로 업데이트`}</p>}

        </div>

        {/* <audio ref={audioRef} src={alarm01}></audio> */}
        {/* <audio ref={audioRef} src={alarm01} controls></audio> */}
        <hr />

        {
          orderList.length > 0 &&
          orderList.map((order, i) => {
            return (
              <div
                key={`${i}-${order.orderId}`}
                style={{
                  ...styles.orderBox,
                  backgroundColor: (order.orderStatus === orderStatus.ordered)
                    ? colors.danger
                    : (order.orderStatus === orderStatus.accepted) ? colors.primary : colors.secondary
                }}
                onClick={() => onClickUpdateOrder(order)}
                name={order.orderId}
              >
                <OrderSummary order={order} {...pageProps} />
                <OrderItems order={order} {...pageProps} />
              </div>
            )
          })
        }
      </CardContainer>
      {selectedOrder !== null &&
        <ModalOrderStatus
          {...pageProps}
          visible={visible}
          setVisible={setVisible}
          orderInfo={selectedOrder}
          // onload={onload}
          setOrderList={setOrderList}
        />
      }
    </Page>
  )
}

function OrderSummary({ shopInfo, order, currentTime, isMobileDevice }) {
  const posName = shopInfo.posName

  const { itemList3, itemOptions3 } = useSelector(state => state.items)
  const { gStyle, colors } = useStyles()

  // const tableNo = order.orderData.TableCode
  const serialNo = order.orderSerialNo
  let tableNo = 1
  if(posName==="UNIONPOS") tableNo = order.orderData.TableCode
  if(posName==="OASIS_POS") tableNo = order.orderData.tableNo

  const timeElapsed = Math.floor((currentTime - order.created) / (1000));
  const min = Math.floor(timeElapsed / 60);
  const sec = Math.floor(timeElapsed % 60);

  const orderStatusTxt = (order.orderStatus === orderStatus.ordered)
    ? "신규 주문" : (order.orderStatus === orderStatus.accepted)
      ? "메뉴 준비 중" : "메뉴 준비 완료"

  const orderStatusTxt2 = (order.isPaid ? "선결제" : "미결제") + " - " + orderStatusTxt

  const styles = {
    orderSummay: {
      margin: 5,
      width: isMobileDevice ? "100%" : "50%",
      padding: 5
    },
    title: { ...gStyle.title, color: colors.light },
    line: { ...gStyle.text, color: colors.light }
  }

  return (
    <div style={styles.orderSummay}>
      <h2 style={styles.title}>주문번호: {`${serialNo} (${orderStatusTxt2})`} </h2>
      <p style={styles.line}>{`테이블명 - ${getTableName({posName, tableNo, itemOptions3 })}`}</p>
      <p style={styles.line}>{`전화번호 - ${getSecurePhoneNumber(order.phone_number)}`}</p>
      <p style={styles.line}>{`주문시간 - ${min}분 ${sec}초 전`}</p>
    </div>
  )
}

function OrderItems({ shopInfo, order, isMobileDevice, itemList, itemOptions }) {
  const [orderHtmlInfo, setOrderHtmlInfo] = useState("")

  useEffect(() => {
    let res = getItemHtmlInfo({ shopInfo, orderData: order.orderData, itemList, itemOptions })
    setOrderHtmlInfo(res)
  }, [order])

  const createMarkup = (html) => {
    return { __html: html };
  }

  const styles = {
    itemsBox: {
      margin: 5,
      width: isMobileDevice ? "95%" : "50%",
      display: "flex",
      flexDirection: "column", justifyContent: "center",
      backgroundColor: "#fff",
      borderRadius: 5,
      padding: 8
    },
  }

  return (
    <div style={styles.itemsBox}>
      <div dangerouslySetInnerHTML={createMarkup(orderHtmlInfo)} />
    </div>
  )
}

function getTableName({ posName, tableNo, itemOptions3 }) {
  let tableName = ""
  
  if(posName==="UNIONPOS") {
    const tableList = itemOptions3.TableList
    const tables = tableList.filter(tbl => tbl.TableNo === tableNo)
    if (tables.length === 1) tableName = tables[0].TableName
  }

  if(posName==="OASIS_POS") {
    const tableList = itemOptions3.tableList
    const tables = tableList.filter(tbl => tbl.tableNo === tableNo)
    if (tables.length === 1) tableName = tables[0].tableName
  }

  return tableName
}
