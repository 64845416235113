
export const shopItem_oasispos = {
  // 
  // tableName = shopItems
  // key = shopId, id
  // 
  name: "shopItem",
  inputFields: [

    /**
     * @POS메뉴에서_가져온_오리지널_속성_편집불가
     */
    { name: "groupCode", posName: "gcode" },                  // 그룹코드
    { name: "groupName", posName: "gname" },                  // 그룹명
    { name: "itemCode", posName: "mcode" },
    { name: "itemName", posName: "mname" },
    // { name: "dporder", posName: "dporder" },               // Number, sortOrder 로 대신함
    { name: "price", posName: "unitprice1" },                 // Number

    // 
    // 무슨 의미
    // 
    { name: "isset", posName: "isset" },
    { name: "vat", posName: "vat" },
    // { name: "soldout", posName: "soldout" },               // isSoldout 으로 대신함
    { name: "chk_main", posName: "chk_main" },

    /**
     * @ONL메뉴에서_편집_가능한_속성들
     */
    { name: "itemName2", defaultValue: { posName: "mname" } },
    { name: "description", defaultValue: "" },

    { name: "sortOrder", defaultValue: { posName: "dporder" } },
    { name: "image", defaultValue: "" },
    { name: "removed", defaultValue: false },                         // ONL 메뉴에서 제거하고 싶을 때
    { name: "isSoldout", defaultValue: false },
    { name: "isNew", defaultValue: false },
    { name: "isHot", defaultValue: false },
    { name: "isBest", defaultValue: false },

    // 
    // POS 언어 저장, 기본값 undefined
    // 
    // { name: "posNameLang" },                  // {KR, EN, CN, JP}
    // { name: "posDescriptionLang" },    // {KR, EN, CN, JP}
  ],
};


/**
 * itemOptions 용 original data 예시
 */

const menuGroupList = [
  {
    gcode: '100',
    gname: '메인메뉴',
    dporder: 999,
    edate: '        ',
    gcode_parent: '',

    // ONL params
    name: "",
    displayName: "",
    nameLang: {},
    sortOrder: 999
  }
]

const setMenuList = [
  {
    setcode: '800000',
    subcode: '800004',
    chk_main: ' ',
    qty: 0,
    unitprice: 0,
    isbase: '0',
    seq: 1,
    settype: 1,
    ispresel: '0',
    vat: '1',
    maxqty: 0
  },
  // {
  //   setcode: '800000',
  //   subcode: '800002',
  //   chk_main: ' ',
  //   qty: 0,
  //   unitprice: 0,
  //   isbase: '0',
  //   seq: 2,
  //   settype: 1,
  //   ispresel: '0',
  //   vat: '1',
  //   maxqty: 0
  // },
]

const tableList = [
  {
    tableGroup: 'F001',
    tableGroupName: 'T',
    tableNo: 0,
    tableName: '1',

    // ONL
    TableNo: "0",
    TableName: "1",
  },
  // {
  //   tableGroup: 'F001',
  //   tableGroupName: 'T',
  //   tableNo: 1,
  //   tableName: '2'
  // },
  // {
  //   tableGroup: 'F001',
  //   tableGroupName: 'T',
  //   tableNo: 2,
  //   tableName: '3'
  // },
]